import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Meta } from '@capturum/api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MapItem } from '@capturum/ui/api';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cpb-list-renderer-paginator',
  templateUrl: './list-renderer-paginator.component.html',
  styleUrls: ['./list-renderer-paginator.component.scss'],
})
export class ListRendererPaginatorComponent implements OnInit, OnDestroy {
  @Input()
  set perPageOptions(value: MapItem[]) {
    if (value) {
      this._perPageOptions = value;
    }
  }

  get perPageOptions(): MapItem[] {
    return this._perPageOptions;
  }

  public get paginator(): Meta['pagination'] {
    return this._paginator;
  }

  @Input()
  public set paginator(value: Meta['pagination']) {
    this._paginator = value;

    if (value) {
      this.paginatorForm.patchValue({ perPage: value.per_page, page: value.current_page }, { emitEvent: false });
    }
  }

  @Input() public paginatorForm: FormGroup;

  @Input() public loading: boolean;
  @Input() public showFirstLastIcon = false;
  @Output() public changePage: EventEmitter<number> = new EventEmitter<number>();
  @Output() public changePerPage: EventEmitter<number> = new EventEmitter<number>();
  @Output() public paginatorChanges: EventEmitter<Record<string, any>> = new EventEmitter();
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private _paginator: Meta['pagination'];
  private _perPageOptions: MapItem[];

  constructor(private readonly formBuilder: FormBuilder) {
    this.paginatorForm = this.formBuilder.group({
      perPage: [null],
      page: [null],
    });
    this._perPageOptions = [10, 20, 50, 100].map((amount) => ({ label: amount.toString(), value: amount }));
  }

  public ngOnInit(): void {
    this.paginatorForm.valueChanges
      .pipe(debounceTime(555), takeUntil(this.destroy$))
      .subscribe((value) => this.paginatorChanges.emit(value));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
