import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Paginator } from 'primeng/paginator';

@Component({
  selector: 'cap-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class CapturumPaginatorComponent {
  @Input() public pagination: {
    total: number;
    count: number;
    per_page: number;
    current_page: number;
    total_pages: number;
  };
  @Input() public showFirstLastIcon = false;
  @Output() public changePage: EventEmitter<number> = new EventEmitter();
  @ViewChild(Paginator) public primePaginator: Paginator;
}
