@if (data?.length > 0) {
  <div class="totals-row">
    <cap-info-table
      class="=info-table info-table--outline totals"
      [stateKey]="stateKey"
      [columns]="columns"
      [data]="data"
      [lazyLoading]="true"
      [loading]="loading"
      [pagination]="false">
      <ng-template capTemplate="title">
        <div class="totals-title">{{ 'builders.table.totals.label' | translate }}</div>
      </ng-template>

      <ng-container>
        @for (column of columns; track column.id) {
          @if (column.field && data[0].hasOwnProperty(column.field)) {
            <ng-template [capTemplate]="column.field" let-item="item">
              <div class="totals-value">{{ item[column.field] }}</div>
            </ng-template>
          }
        }
      </ng-container>

      <ng-container skeleton>
        <cap-skeleton height="50px"></cap-skeleton>
      </ng-container>
    </cap-info-table>
  </div>
}
