import { Observable } from 'rxjs';
import { InfoTableColumnType } from './info-table-column-type.enum';
import { LazyLoadMeta, SortMeta } from 'primeng/api';

export interface InfoTableColumn {
  field: string;
  title?: Observable<string> | string;
  type?: InfoTableColumnType;
  sortable?: {
    enabled: boolean;
    direction: 'asc' | 'desc';
    field?: string;
  };
  hidden?: boolean;
  titleClass?: string;
  cellClass?: string;
  disableRowClick?: boolean;
  /**
   * @deprecated Use sortable.enabled
   */
  disableSort?: boolean;
  frozen?: boolean;
  frozenColumnWidth?: number;
  showTotals?: boolean;
  [key: string]: any;
}

export interface InfoTableSortColumnEvent {
  current: SortMeta;
  lazyLoadMetaData: LazyLoadMeta;
}
