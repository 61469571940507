<ng-container *ngIf="props.options$ | async as options">
  <cap-dropdown
    [formControl]="formControl"
    [hidden]="props.readonly"
    [options]="options"
    [attr.data-test]="'builder-' + field.key"
    [optionLabel]="'label'"
    [optionValue]="'key'"
    [placeholder]="props.placeholder || ('placeholder.select' | translate)"
    [floatLabel]="(props.label_position | observablePipe | async) === labelPosition.Floating"
    [label]="(props.label_position | observablePipe | async) === labelPosition.Floating ? props.label : ''"
    [appendTo]="props?.appendTo || 'body'"
    [readonly]="props.readonly"
    [virtualScroll]="props.virtualScroll"
    [showClear]="true"
    [withImages]="!!props.image_key"
    [withIcons]="props.withIcons"
    [virtualScrollItemSize]="props.virtualScrollItemSize || 30"
    [filter]="true"
    [filterBy]="props.filterBy"
    [panelStyleClass]="props.css_class"
    [sortAlphabetically]="props?.sort_asc"
    [tabindex]="props.tabindex || 0">
  </cap-dropdown>

  <cpb-readonly-container [hidden]="!props.readonly" [attr.data-test]="'builder-readonly-' + field.key">
    {{ [formControl.value] | labelsByValue: options | default: props?.defaultEmptyValue }}
  </cpb-readonly-container>
</ng-container>
