import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CapturumSharedModule } from '@capturum/ui/api';
import { NgTemplateOutlet } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CapturumInfoTableModule, InfoTableColumn } from '@capturum/ui/info-table';
import { CapturumSkeletonModule } from '@capturum/ui/skeleton';

@Component({
  selector: 'cpb-list-renderer-totals',
  standalone: true,
  imports: [CapturumSharedModule, TranslateModule, CapturumInfoTableModule, NgTemplateOutlet, CapturumSkeletonModule],
  templateUrl: './list-renderer-totals.component.html',
  styleUrls: ['./list-renderer-totals.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListRendererTotalsComponent {
  @Input() public columns: InfoTableColumn[];
  @Input() public data: Record<string, number>[] = [];
  @Input() public stateKey: string;
  @Input() public loading = true;
}
