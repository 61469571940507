import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { InfoTableColumn } from '../../base/info-table-column.model';

@Component({
  selector: 'cap-info-table-header',
  templateUrl: './info-table-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoTableHeaderComponent {
  @Input() public selectable: boolean;
  @Input() public sortable: boolean;
  @Input() public hasFrozenColumns: boolean;
  @Input() public templates: QueryList<any>;
  @Input() public columns: InfoTableColumn[];
  @Input() public reorderableRows: boolean;
  @Input() public reorderableColumns = false;
  @Input() public editableRows: boolean;
  @Output() public sortColumn: EventEmitter<InfoTableColumn> = new EventEmitter();
}
