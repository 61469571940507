import { InfoTableColumnType } from '@capturum/ui/info-table';
import { ListOptions } from '@capturum/api';
import { ListRendererFilterConfig } from '@capturum/builders/core';
import { PaginationType } from '../enums/pagination-type.enum';
import { ListRendererActionType } from '../enums/list-renderer-action-types.enum';
import { HeaderType } from '../enums/header-type.enum';
import { ListRendererBreakpoints } from '../enums/breakpoints.enum';

export interface ListRendererConfig {
  id?: string;
  type?: string;
  columns: ListRendererColumnConfig[];
  actions: ListRendererActionConfig[];
  filters: ListRendererFilterConfig;
  entity: string;
  globalSearch?: boolean;
  source: {
    endpoint: string;
    options?: ListOptions;
  };
  pagination?: PaginationType;
  headerPosition: HeaderType;
  options?: {
    cards_view?: boolean;

    [key: string]: any;
  };
  noDataOnInit?: boolean;
}

export interface ListRendererColumnConfig {
  id?: string;
  column: string;
  title?: string;
  field?: string;
  widget?: ListRendererWidgetConfig;
  type?: InfoTableColumnType;
  sortable?: boolean | 'asc' | 'desc';
  totals?: boolean;
  searchable?: boolean;
  readonly?: boolean;
  disableRowClick?: boolean;
  options?: Record<string, any>;
  breakpoints?: Record<ListRendererBreakpoints, boolean>;
}

export interface ListRendererActionConfig {
  id?: string;
  key: string;
  name: string;
  permission: string;
  icon: string;
  type: ListRendererActionType;
  action: {
    type: string;
    route: string;
  };
}

export interface ListRendererWidgetConfig {
  name: string;
  options?: Record<string, any>;
}
